<script lang="ts">
	import { setUser } from '$lib/stores/UserStore';
	import { arrow, autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';
	import { Modal, Toast, initializeStores, storePopup } from '@skeletonlabs/skeleton';
	import '../app.pcss';

	initializeStores();
	storePopup.set({ computePosition, autoUpdate, offset, shift, flip, arrow });

	export let data;
	$: setUser(data.user);
</script>

<Modal buttonPositive={'btn variant-filled-primary'} />
<Toast
	background="bg-white border border-primary-500 px-2 py-2"
	buttonAction="btn variant-ringed-primary hover:bg-secondary-400 py-0 px-1"
	buttonDismiss="btn-icon btn-icon-sm variant-ringed-primary hover:bg-secondary-400 w-6"
	color="text-primary-500"
	position="br"
/>

<slot />

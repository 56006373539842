import { browser } from '$app/environment';
import { PUBLIC_POSTHOG_HOST, PUBLIC_POSTHOG_KEY } from '$env/static/public';
import posthog from 'posthog-js';
import type { LayoutLoad } from './$types';

/**
 * We use this load function to initialize posthog and collect app analytics in the FE.
 */
export const load = (async ({ data }) => {
	if (browser && import.meta.env.PROD) {
		posthog.init(PUBLIC_POSTHOG_KEY, {
			api_host: `/ingest`,
			ui_host: PUBLIC_POSTHOG_HOST,
		});
		if (data?.user) {
			posthog.identify(data.user.id, { email: data.user.email });
		} else {
			posthog.reset();
		}
	}
	return { ...data };
}) satisfies LayoutLoad;
